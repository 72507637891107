import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import theme from '../styling/theme'
import circle from '../../images/risk/90-chart.svg'
import Button from '../shared/Button/Button'
import cross from '../../images/risk/doc-cross.svg'
import profile from '../../images/risk/profile.png'
import questionMark from '../../images/risk/question.svg'
import blueBorder from '../../images/spinner.svg'
import { appendUrlPartnerTracking } from '../../util/url'

const data = {
  circleSubtitle: 'of breaches start with an employee mistake',
  percent: '90',

  btnTitle1: 'Get started',
  btnUrl1: '/pricing',
  btnUrl2: '/get-in-touch',
  btnTitle2: 'Get in touch',
}

const cardsContent = [
  { text: 'Phishing attack' },
  { text: 'Malware attack' },
  { text: 'Ransomware' },
  { text: 'Trojan horse' },
  { text: 'Malware' },
  { text: 'Spear phishing' },
]

const baseContent = {
  imgSrc: profile,
  imgAlt: 'Profile image',
}

interface RiskProps {
  circleColTitle: string
  circleColBody: string
  cyberBreachCard1: string
  cyberBreachCard2: string
}
const Risk: FunctionComponent<RiskProps> = ({
  circleColTitle,
  circleColBody,
  cyberBreachCard1,
  cyberBreachCard2,
}) => {
  return (
    <Outer>
      <Container>
        <Col>
          <Inner align={'flex-start'}>
            <CircleContainer>
              <Percent>{data.percent}%</Percent>
              <Subtitle>{data.circleSubtitle}</Subtitle>
            </CircleContainer>
            <CircleColText>
              <CircleColTitle>{circleColTitle}</CircleColTitle>
              <CircleColBody>{circleColBody}</CircleColBody>
            </CircleColText>
            <ButtonContainer>
              <Button href={appendUrlPartnerTracking(data.btnUrl1)}>
                {data.btnTitle1}
              </Button>
              {data.btnUrl2 && (
                <Button href={appendUrlPartnerTracking(data.btnUrl2)} secondary>
                  {data.btnTitle2}
                </Button>
              )}
            </ButtonContainer>
          </Inner>
        </Col>
        <Col>
          <Inner align={'flex-end'}>
            <Input>
              {cardsContent.map((item, key) => {
                return (
                  <CardInput delay={key * 2} key={key}>
                    <QuestionMark src={questionMark} alt="Question Mark" />
                    <LinesContainer>
                      <LineInput />
                      <LineInput />
                      <LineInput />
                      <HalfLineInput />
                    </LinesContainer>
                  </CardInput>
                )
              })}
            </Input>
            <Base>
              <ProfileWrapper>
                <Profile src={baseContent.imgSrc} alt={baseContent.imgAlt} />
                <ProfileBlueBorder src={blueBorder} alt="blue border" />
              </ProfileWrapper>
              {cyberBreachCard1 && <Title>{cyberBreachCard1}</Title>}
              {cyberBreachCard2 && <Body>{cyberBreachCard2}</Body>}
            </Base>
            <Output>
              {cardsContent.map((item, key) => {
                return (
                  <Card key={key} delay={key * 2} className="output-card">
                    <Cross src={cross} alt="Cross" />
                    <Text>{item.text}</Text>
                    <LinesContainer>
                      <Line />
                      <Line />
                      <Line />
                      <HalfLine />
                    </LinesContainer>
                  </Card>
                )
              })}
            </Output>
          </Inner>
        </Col>
      </Container>
    </Outer>
  )
}

export default Risk

const Outer = styled.section`
  @media (min-width: 1024px) {
    background: linear-gradient(
      90deg,
      ${theme.background.hpHeader} 0%,
      ${theme.background.hpHeader} 50%,
      rgba(255, 255, 255, 0) 51%,
      rgba(255, 255, 255, 0) 100%
    );
  }
`

const Container = styled.div`
  // padding: 78px 0px 0px;
  display: flex;
  flex-direction: column;
  width: 100%;
  border-bottom: 1px solid ${theme.grey.keylineDark};
  @media (min-width: 1024px) {
    padding: 0;
    border-bottom: none;
    flex-direction: row;
    align-items: center;
    width: 100%;
    max-width: 1400px;
    margin: 0 auto;
  }
`
const Col = styled.div`
  width: 100%;
  text-align: center;

  &:first-of-type {
    background: ${theme.background.hpHeader};
    padding: 78px 20px 0;
  }
  @media (min-width: 1024px) {
    width: 50%;
    padding: 0;
  }
`
interface InnerProps {
  align: 'flex-end' | 'flex-start'
}

const Inner = styled.div<InnerProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (min-width: 1024px) {
    width: 90%;
    justify-content: ${(props) => props.align && props.align};
    margin-left: ${(props) => (props.align === 'flex-end' ? 'auto' : '3rem')};
  }
  @media (min-width: 1200px) {
    margin-left: ${(props) => (props.align === 'flex-end' ? 'auto' : '0')};
  }
`

const CircleContainer = styled.div`
  position: relative;
  background: url(${circle});
  background-repeat: no-repeat;
  width: 280px;
  height: 280px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto 40px;
  @media (min-width: 1024px) {
    width: 310px;
    height: 310px;
    margin-bottom: 52px;
  }
`
const Percent = styled.span`
  font-size: 80px;
  font-weight: bold;
  letter-spacing: -3px;
  line-height: 97px;
  text-align: center;
  @media (min-width: 1024px) {
    font-size: 90px;
    line-height: 110px;
  }
`

const Subtitle = styled.p`
  text-align: center;
  width: 100%;
  max-width: 65%;
`

const CircleColText = styled.div`
  width: 100%;
  @media (min-width: 1024px) {
    max-width: 400px;
    margin: 0 auto;
  }
  @media (min-width: 1199px) {
    max-width: 520px;
  }
`

const CircleColTitle = styled.h4`
  margin-bottom: 12px;

  @media (min-width: 1024px) {
    margin-bottom: 24px;
  }
`

const CircleColBody = styled.p``

const ButtonContainer = styled.div`
  & a:first-of-type {
    margin-bottom: 20px;
  }

  & a {
    margin-left: auto;
    margin-right: auto;
  }
  margin: 27px auto 65px;
  display: flex;
  flex-direction: column;
  width: 100%;
  @media (min-width: 1024px) {
    max-width: 420px;
    flex-direction: row;
    justify-content: space-between;
    margin: 37px auto 0;
    & a:first-of-type {
      margin-right: 20px;
      margin-bottom: 0px;
    }
  }
`
interface CardProps {
  delay: number
}

const Card = styled.div<CardProps>`
  height: 148px;
  width: 148px;
  border: 1px solid ${theme.text.main};
  border-radius: 4px;
  background-color: white;
  position: relative;
  margin-bottom: 32px;
  padding: 37px 20px 22px;
  text-align: left;
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 50%;
  transform: translate3D(-50%, -100%, 0);
  justify-content: space-between;
  animation: slideshow 12s linear infinite -${(props) => props.delay && props.delay}s;
  transition: all 2s ease;
  will-change: transform;
  @keyframes slideshow {
    0% {
      transform: translate3D(-50%, -100%, 0);
    }
    100% {
      transform: translate3D(-50%, 932px, 0);
    }
  }
`
const CardInput = styled(Card)`
  padding: 24px 20px 22px;
  margin-bottom: 12px;
`

const Cross = styled.img`
  height: 40px;
  width: 40px;
  position: absolute;
  top: -15px;
  right: 10%;
`

const QuestionMark = styled.img`
  width: 39px;
  height: 39px;
`

const LinesContainer = styled.div``

const Line = styled.div`
  width: 100%;
  height: 6px;
  background: ${theme.brand.blue};
  margin-bottom: 4px;
`

const HalfLine = styled(Line)`
  width: 50%;
  margin-bottom: 0px;
`
const LineInput = styled(Line)`
  background: #dadada;
`
const HalfLineInput = styled(HalfLine)`
  background: #dadada;
`

const Text = styled.p`
  font-size: 13px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 16px;
  color: ${theme.brand.blue};
`

const Base = styled.div`
  background-color: #ffffff;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
  border-top: 6px solid ${theme.brand.blue};
  padding: 47px 32px 58px;
  width: 100%;
  max-width: 305px;
  position: relative;
  z-index: 4;
  @media (min-width: 1024px) {
    padding: 33px 35px 41px;
    max-width: 350px;
  }
  &::before {
    content: '';
    display: block;
    position: absolute;
    bottom: -12px;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 8px;
    width: 148px;
    opacity: 0.3;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
  }
`
const ProfileWrapper = styled.div`
  position: relative;
  width: 144px;
  height: 144px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 6px;
`

const Profile = styled.div`
  background: url(${profile});
  background-repeat: no-repeat;
  background-size: cover;
  width: 130px;
  height: 130px;
`
const ProfileBlueBorder = styled.img`
  position: absolute;
  width: 144px;
  height: 144px;
  animation: animateBorder 1s linear infinite;
`

const Title = styled.h2`
  margin-bottom: 12px;
`

const Body = styled.p`
  font-size: 14px;
  line-height: 22px;
  color: ${theme.text.main};
`
const Input = styled.div`
  height: 333px;
  width: 200px;
  overflow: hidden;
  z-index: 1;
  position: relative;
`
const Output = styled(Input)`
  height: 370px;
`
