import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import PromotionBanner from '../components/PromotionBanner'
import LogosGrid from '../components/LogosGrid'
import CarouselDrag from '../components/CarouselDrag'
import TrackVisibility from 'react-on-screen'
import HomepageHeader from '../components/Header/HomepageHeader'
import DotsAnimation from '../components/DotsAnimation/DotsAnimation'
import Empower from '../components/Empower/Empower'
import Courses from '../components/Courses/Courses'
import SolutionsCards from '../components/SolutionsCards/SolutionsCards'
import Risk from '../components/Risk/Risk'

import { LazyBlocksFactory } from '../components/LazyBlocksFactory/LazyBlocksFactory'


interface Props {
  data: any
  pageContext: any
}

const Index = ({ data, pageContext }: Props) => {
  const { wpPage } = data
  const { allWpTestimonial } = data
  const { home } = wpPage
  const renderedBlocks = LazyBlocksFactory(wpPage.lazy_data, wpPage.title)

  const { allWpOrganisationSticker } = data
  console.log(process.env.GATSBY_AWS_BRANCH)
  return (
    <Layout>
      <TrackVisibility partialVisibility offset={400}>
        <HomepageHeader
          title1={home.title1}
          title2={home.title2}
          subtitle={home.subsubtitletitle}
          isVisible={true}
          btnTitle1={home.ctaText1}
          btnTitle2={home.ctaText2}
          btnUrl1={home.ctaUrl1}
          btnUrl2={home.ctaUrl2}
        />
      </TrackVisibility>

      <SolutionsCards
        topHeadingMarginBottom={'0'}
        topHeading={home.titleCaption}
        heading={home.postTitle}
      />
      <TrackVisibility partialVisibility offset={400}>
        <DotsAnimation
          withBobHeading={home.withBobTitle}
          withBobText={home.withBobDescription1}
          withBobBody={home.withBobDescription2}
           withoutBobHeading={home.withoutBobTitle}
          //withoutBobHeading="Cyber security with standard training courses"
          withoutBobText={home.withoutBobDescription1}
          withoutBobBody={home.withoutBobDescription2}
          isVisible={true}
        />
      </TrackVisibility>
      <TrackVisibility partialVisibility>
        <Empower
          blueText={home.splashCard1}
          whiteText={home.splashCard2}
          isVisible={true}
        />
      </TrackVisibility>
      <TrackVisibility partialVisibility offset={50}>
        <Courses
          data={{
            topHeading: home.meetBobSubtitle,
            heading: home.meetBobTitle,
            body: home.meetBobSummary,
          }}
          cardsContent={[
            {
              title: home.meetBobCardTitle1,
              body: home.meetBobCardDescription1,
            },
            {
              title: home.meetBobCardTitle2,
              body: home.meetBobCardDescription2,
            },
            {
              title: home.meetBobCardTitle3,
              body: home.meetBobCardDescription3,
            },
          ]}
        />
      </TrackVisibility>

      <Risk
        circleColTitle={home.cyberBreach1}
        circleColBody={home.cyberBreach2}
        cyberBreachCard1={home.cyberBreachCard1}
        cyberBreachCard2={home.cyberBreachCard2}
      />
      <CarouselDrag
        heading="What others are saying"
        data={allWpTestimonial.nodes}
        id="carousel-drag-container"
      />
      <TrackVisibility partialVisibility>
        <LogosGrid brandsCaptions={home.brandsCaptions} images={allWpOrganisationSticker} isVisible={true} /> 
      </TrackVisibility>
      
      {renderedBlocks.map((v) => v)}
    </Layout>
  )
}

export default Index

export const pageQuery = graphql`
  query MyQuery {
    wpPage(title: { eq: "Home" }) {
      id
      title
      lazy_data
      home {
        brandsCaptions
        ctaText1
        ctaText2
        ctaUrl1
        ctaUrl2
        cyberBreach1
        cyberBreach2
        cyberBreachCard1
        cyberBreachCard2
        meetBobCardDescription1
        meetBobCardDescription2
        meetBobCardDescription3
        meetBobCardTitle1
        meetBobCardTitle2
        meetBobCardTitle3
        meetBobSubtitle
        meetBobTitle
        meetBobSummary
        postTitle
        splashCard1
        splashCard2
        subsubtitletitle
        title1
        title2
        titleCaption
        withBobDescription1
        withBobDescription2
        withBobTitle
        withoutBobDescription1
        withoutBobDescription2
        withoutBobTitle

      }
    }
    allWpOrganisationSticker {
      nodes {
        organisational_stickers {
          logo {
            localFile {
              childImageSharp {
                fluid(maxWidth: 600, toFormat: WEBP) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
 
    allWpTestimonial {
      nodes {
        title
        slug
        acfTestimonial {
          description
          initials
          author
          vertical
          logo {
            localFile {
              childImageSharp {
                fluid(maxWidth: 1080, toFormat: WEBP) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
