import React, { useState, FunctionComponent } from 'react'
import styled from 'styled-components'
import { Heading, TopHeading } from '../SolutionsCards/SolutionsCards'
import MainGrid from '../styling/MainGrid'
import theme from '../styling/theme'
// import bob from '../../images/bob-animation/bob-character.png'
// import tablet from '../../images/bob-animation/bob-tablet.png'
// import music from '../../images/bob-animation/bob-music.png'
// import redlines from '../../images/bob-animation/bob-redlines.png'

import InlineLink from '../shared/InlineLink'
import circleMob from '../../images/circle_m.svg'
import circleDesk from '../../images/circle_d.svg'
import Button from '../shared/Button/Button'
import { scrollTo } from '../../util/functions'
import { graphql,useStaticQuery } from 'gatsby'

interface DataProp {
  topHeading?: string
  heading: string
  body: string
}

interface Card {
  title?: string
  body?: string
}

interface CoursesProps {
  data: DataProp
  cardsContent: Array<Card>
  isVisible?: boolean
  background?: string
}

const Courses: FunctionComponent<CoursesProps> = ({
  data,
  cardsContent,
  isVisible,
  background,
}) => {

  const imagesCollection = useStaticQuery(graphql`
    query img2{
      bob: allFile(filter: {childrenImageSharp: {elemMatch: {fluid: {originalName: {eq: "bob-character.png"}}}}}) {
        edges {
          node {
            id
            childImageSharp {
              fluid(maxWidth: 880,toFormat: WEBP) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      },
      bobTable: allFile(filter: {childrenImageSharp: {elemMatch: {fluid: {originalName: {eq: "bob-tablet.png"}}}}}) {
        edges {
          node {
            id
            childImageSharp {
              fluid(maxWidth: 880,toFormat: WEBP) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      },
      bobMusic: allFile(filter: {childrenImageSharp: {elemMatch: {fluid: {originalName: {eq: "bob-music.png"}}}}}) {
        edges {
          node {
            id
            childImageSharp {
              fluid(maxWidth: 880,toFormat: WEBP) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      },
      bobRedLines: allFile(filter: {childrenImageSharp: {elemMatch: {fluid: {originalName: {eq: "bob-redlines.png"}}}}}) {
        edges {
          node {
            id
            childImageSharp {
              fluid(maxWidth: 880,toFormat: WEBP) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)
  
  const bob = imagesCollection.bob.edges[0].node.childImageSharp.fluid.src
  const tablet = imagesCollection.bobTable.edges[0].node.childImageSharp.fluid.src
  const music = imagesCollection.bobMusic.edges[0].node.childImageSharp.fluid.src
  const redlines = imagesCollection.bobRedLines.edges[0].node.childImageSharp.fluid.src


  const [showAllCards, toggleCards] = useState(false)
  const [cardsToShow, setCardsToShow] = useState(1)

  function handleShowMore() {
       if (showAllCards) {
      setCardsToShow(cardsToShow -2)
       }
      else{
        setCardsToShow(cardsToShow+2)
        } 
    toggleCards(!showAllCards)
  }
  return (
    <Container background={background}>
      <MainGrid>
        <Inner>
          <BobWrapper>
            {data && data.topHeading && (
              <TopHeading className="small-p">{data.topHeading}</TopHeading>
            )}
            {data && data.heading && <Heading>{data.heading}</Heading>}

            <ImageWrapper>
              <Tablet src={tablet} alt="Tablet" />
              <Mask>
                <Bob src={bob} alt="Bob character" isVisible={isVisible} />
                <Music src={music} alt="Music notes" isVisible={isVisible} />
                <RedLines
                  src={redlines}
                  alt="Red lines around Bob character"
                  isVisible={isVisible}
                />
              </Mask>
            </ImageWrapper>

            {data && data.body && <BobExplanation>{data.body}</BobExplanation>}
          </BobWrapper>
          <CardsWrapperMobile id={'courses-container'}>
            {cardsContent &&
              cardsContent.slice(0,cardsToShow).map((card, key) => {
                // if (key < 1 || showAllCards) {
                  return (
                    <Card key={key}>
                      <Circle src={circleMob} alt="Circle" />
                      <CircleDesktop src={circleDesk} alt="Circle" />
                      <CardCol>
                        {card.title && <CardTitle>{card.title}</CardTitle>}
                        {card.body && <CardBody>{card.body}</CardBody>}
                      </CardCol>
                    </Card>
                  )
                // }
              })}
            <BtnContainer onClick={() => handleShowMore()}>
              <Button >
                {!showAllCards ? 'Show more' : 'Show less'}
              </Button>
            </BtnContainer>
            <InlineLink
              href={'/courses'}
              text={'Learn more about our courses'}
              margintop={'25px'}
              margintopDesktop={'38px'}
            />
          </CardsWrapperMobile>
          <CardsWrapper id={'courses-container'}>
            {cardsContent &&
              cardsContent.map((card, key) => {
                return (
                  <Card key={key}>
                    <Circle src={circleMob} alt="Circle" />
                    <CircleDesktop src={circleDesk} alt="Circle" />
                    <CardCol>
                      {card.title && <CardTitle>{card.title}</CardTitle>}
                      {card.body && <CardBody>{card.body}</CardBody>}
                    </CardCol>
                  </Card>
                )
              })}
            <InlineLink
              href={'/courses'}
              text={'Learn more about our courses'}
              margintop={'25px'}
              margintopDesktop={'38px'}
            />
          </CardsWrapper>
        </Inner>
      </MainGrid>
    </Container>
  )
}

export default Courses
interface IContainer {
  background?: string
}
const Container = styled.section<IContainer>`
  background: ${(props) =>
    props.background ? props.background : theme.background.courses};
  padding: 50px 0;
  @media (min-width: 768px) {
    padding: 10rem 0;
    border-bottom: 1px solid ${theme.grey.keylineDark};
  }
`
const Inner = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: 768px) {
    flex-direction: row;
    align-items: center;
  }
`

const BobWrapper = styled.div`
  width: 100%;
  @media (min-width: 768px) {
    width: 40%;
    margin-right: 10rem;
  }
`

const ImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 6px;
  padding: 32px 0 20px;
  position: relative;
`
const Mask = styled.div`
  max-width: 40rem;
  position: relative;
  z-index: 2;
  overflow: hidden;
  clip-path: polygon(100% 0, 100% 75%, 30% 99%, 0 49%, 0 0);
`
interface BobProps {
  isVisible?: boolean
}
const Bob = styled.img<BobProps>`
  max-width: 30rem;
  position: relative;
  left: 20%;
  z-index: 2;
  transform: translateY(200px);
  transition: transform 2s ease;
  animation: ${(props) =>
    props.isVisible
      ? 'animateBob 1.5s forwards ease'
      : 'animateBobReverse 1.5s forwards ease'};
`
const Music = styled.img<BobProps>`
  position: absolute;
  left: 20%;
  width: 100%;
  max-width: 30rem;
  transform: scale(0);
  animation: ${(props) =>
    props.isVisible ? 'animateBobLines 1.2s forwards ease 1.3s' : 'none'};
`
const RedLines = styled(Music)`
  transform: scale(0);
  animation: ${(props) =>
    props.isVisible ? 'animateBobLines 1.2s forwards ease 2.4s' : 'none'};
  transform-origin: center center;
`

const Tablet = styled.img`
  max-width: 40rem;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
`

const BobExplanation = styled.p`
  font-weight: 300;
  margin-top: 40px;
`

const CardsWrapper = styled.div`
  width: 100%;
  margin-top: 25px;
  display: none;
  @media (min-width: 768px) {
    width: 55%;
  }
  @media (min-width: 768px) {
    width: 60%;
    display: block;
  }
`
const CardsWrapperMobile = styled.div`
  display: block;
  margin-top: 25px;
  @media (min-width: 768px) {
    display: none;
  }
`
const Card = styled.div`
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
  background: white;
  padding: 27px 28px 32px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  border: 1px solid ${theme.grey.keylineDark};

  &:last-of-type {
    margin-bottom: 35px;
  }
  @media (min-width: 768px) {
    flex-direction: row;
    padding: 32px 52px 32px 37px;
  }
`
const CardCol = styled.div`
  @media (min-width: 768px) {
    margin-left: 26px;
  }
`

const Circle = styled.img`
  width: 20px;
  height: 20px;
  margin-bottom: 12px;

  @media (min-width: 768px) {
    display: none;
  }
`
const CircleDesktop = styled.img`
  display: none;
  @media (min-width: 768px) {
    display: block;
    width: 60px;
    height: 60px;
  }
`
const CardTitle = styled.h6`
  color: ${theme.grey.inputHover};
  margin-bottom: 5px;
`

const CardBody = styled.p`
  color: ${theme.grey.inputHover};
`

const BtnContainer = styled.div`
  margin-top: 20px;
`
