import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import MainGrid from './styling/MainGrid'
import VerticalPadding from './styling/VerticalPadding'
import SectionHeading from './shared/SectionHeading'
import Img from 'gatsby-image'
import theme from './styling/theme'


interface LogosGrid {
  isVisible: boolean
  brandsCaptions: any
  images?:any
}
const LogosGrid: FunctionComponent<LogosGrid> = ({
  isVisible,
  brandsCaptions,
  images,
}) => {

  //const featureimage = brandsCaptions.organisational_stickers.logo.localFile.childImageSharp.fluid
  return (
    <Container>
      <MainGrid>
        <VerticalPadding>
          <SectionHeading heading={brandsCaptions} center maxwidth={'75rem'} />
          <Grid>
            {images &&
              images.nodes.slice(0, 6).map((item, key) => {
               
                return (
                  <Mask key={key} order={key}>
                    
             
                  <ImageContainer
                   isVisible={isVisible}
                      delay={Math.random() * 0.5 * key}>

                    <Img
                      fluid={item.organisational_stickers.logo.localFile.childImageSharp.fluid}
                      alt={item.alt}
                      // style={{ marginLeft: 'auto', display: 'block'}}
                      //  imgStyle={{ width: 'auto', height: 75, maxHeight:80, margin: '0 auto'}}
                      //isVisible={isVisible}
                      //delay={Math.random() * 0.5 * key}
                      style={{ height: "100%", width: "100%" }}
                      imgStyle={{ objectFit: "contain" }}
  
                      />
                    </ImageContainer>
                  
                  </Mask>
                  
                )

              })}
            
          </Grid>
        </VerticalPadding>
      </MainGrid>
    </Container>
  )
}

export default LogosGrid

const Container = styled.section`
  border-top: 1px solid ${theme.grey.keylineDark};
`

const Grid = styled.div`
  display: grid;
  align-items: center;
  justify-content: space-between;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 115px 115px;
  grid-column-gap: 1px;
  grid-row-gap: 1px;
  background: ${theme.grey.keyline};
  margin: 0 auto;
  @media (min-width: 1200px) {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 200px 200px;
  }
`

interface MaskProps {
  order: number
}

const Mask = styled.div<MaskProps>`
  width: 100%;
  height: 100%;
  background: white;

  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  @media screen and (max-width: 767px) {
    display: ${(props) =>
      props.order === 4 || props.order === 5 ? 'none' : 'flex'};
  }
  @media screen and (min-width: 768px) {
    padding: 5rem 10rem;
  }
`

interface ImgProps {
  isVisible: boolean
  delay: number
}
const ImageContainer = styled.div<ImgProps>`
  
  width: 200px;
  height: 100px;
  align-content: center;
  justify-items: center;
   vertical-align:middle;
  transform: ${(props) => (props.isVisible ? 'scale(1)' : 'scale(0.5)')};
  transition: transform 1s ease;
  transition-delay: ${(props) => props.delay && props.delay}s; 
  @media (max-width: 768px) {
    width:100px;
  }
  @media (max-width: 1024px) {
    width:150px;
  }
`


const IMG = styled(Img)`
vertical-align: 'middle';

height:75px;
object-fit: contain;
//margin: 0 auto;


`