import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
// import image from '../../images/empower.png'
// import lines from '../../images/empower-lines.png'
import MainGrid from '../styling/MainGrid'
import theme from '../styling/theme'

import { graphql,useStaticQuery } from 'gatsby'

const circlesRightNumber = [
  {
    width: 28,
    border: 5,
    top: 100,
    right: 0,
  },
  {
    width: 14,
    border: 3,
    top: 90,
    right: 5,
  },
  {
    width: 10,
    border: 2,
    top: 80,
    right: 8,
  },
  {
    width: 12,
    border: 3,
    top: 70,
    right: 12,
  },
  {
    width: 22,
    border: 4,
    top: 90,
    right: 6,
  },
  {
    width: 18,
    border: 4,
    top: 92,
    right: 0,
  },
]



interface EmpowerProps {
  isVisible: boolean
  blueText: string
  whiteText: string
}
const Empower: FunctionComponent<EmpowerProps> = ({
  isVisible,
  blueText,
  whiteText,
}) => {

  const imagesCollection = useStaticQuery(graphql`

    query img4 {
      image: allFile(filter: {childrenImageSharp: {elemMatch: {fluid: {originalName: {eq: "empower.png"}}}}}) {
        edges {
          node {
            id
            childImageSharp {
              fluid(maxWidth: 660,toFormat: WEBP) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      },
      lines: allFile(filter: {childrenImageSharp: {elemMatch: {fluid: {originalName: {eq: "empower-lines.png"}}}}}) {
        edges {
          node {
            id
            childImageSharp {
              fluid(maxWidth: 660,toFormat: WEBP) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      },
    }
  `)

  const image = imagesCollection.image.edges[0].node.childImageSharp.fluid.src
  const lines = imagesCollection.lines.edges[0].node.childImageSharp.fluid.src

  const data = {
    img: image,
    imgAlt: 'Empower ..',
  }

  return (
    <Container>
      <MainGrid noPaddingMobile>
        <InnerWrapper>
          <ImgWrapper>
            <Img src={data.img} alt={data.imgAlt} isVisible={isVisible} />
            <Lines src={lines} alt={'Red lines'} isVisible={isVisible} />
            <CirclesContainerRight>
              {circlesRightNumber &&
                circlesRightNumber.map((circle, key) => {
                  return (
                    <CircleSmall
                      key={key}
                      isVisible={isVisible}
                      width={circle.width}
                      left={0}
                      border={circle.border}
                      top={circle.top}
                      right={circle.right}
                      delay={key + 1}
                    />
                  )
                })}
            </CirclesContainerRight>
          </ImgWrapper>
          <TextWrapper>
            <BlueText>{blueText}</BlueText>
            <WhiteText>{whiteText}</WhiteText>
          </TextWrapper>
        </InnerWrapper>
      </MainGrid>
      <BigCircle isVisible={isVisible} />
    </Container>
  )
}

export default Empower

const Container = styled.section`
  background: ${theme.grey.scroll};
  position: relative;
  padding: 50px 0 0;
  @media (min-width: 1024px) {
    padding: 0;
  }
`
const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  @media (min-width: 768px) {
    flex-direction: row-reverse;
    padding: 8rem 20px 0;
  }
  @media (min-width: 1024px) {
    padding: 10rem 0 0;
  }
`

const ImgWrapper = styled.div`
  width: 70%;
  margin: 0 auto;
  display: flex;
  align-items: flex-end;
  position: relative;

  @media (min-width: 768px) {
    margin: 0 0 0 8rem;
  }
  @media (min-width: 1024px) {
    margin: 0 0 0 10rem;
    width: 50%;
    background: ${theme.grey.scroll};
    overflow: hidden;
  }
`

interface ImgProps {
  isVisible: boolean
}
const Img = styled.img<ImgProps>`
  @media (min-width: 1200px) {
    transform: translateX(-800px);
    animation: ${(props) =>
      props.isVisible ? 'moveLeftEmpower 1s ease forwards' : 'none'};
  }
`

interface LinesProps {
  isVisible: boolean
}
const Lines = styled.img<LinesProps>`
  position: absolute;
  bottom: 0;
  transform: scale(0);
  animation: ${(props) =>
    props.isVisible ? 'linesSound1 2s ease infinite 2s' : 'none'};
  transform-origin: bottom right;
`

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 2;
  @media (min-width: 768px) {
    width: 47%;
    padding-bottom: 8rem;
  }
  @media (min-width: 1024px) {
    padding-bottom: 20rem;
    width: 43%;
    padding-top: 5rem;
  }
`

const BlueText = styled.h4`
  color: ${theme.text.white};
  background: ${theme.brand.blue};
  padding: 40px 35px 50px;
  @media screen and (min-width: 1024px) {
    padding: 54px 54px 60px;
  }
`

const WhiteText = styled.div`
  color: ${theme.text.second};
  background: ${theme.text.white};
  padding: 43px 35px 50px;
  font-size: 14px;
  font-weight: 300;
  line-height: 24px;
  @media screen and (min-width: 1024px) {
    padding: 54px 54px 60px;
    font-size: 16px;
    line-height: 28px;
  }
`

interface BigCirlceProps {
  isVisible: boolean
}
const BigCircle = styled.div<BigCirlceProps>`
  display: none;
  width: 55rem;
  height: 55rem;
  border: 8rem solid ${theme.brand.blue20};
  opacity: 0.4;
  border-radius: 50%;
  position: absolute;
  transform-style: preserve-3d;
  top: 100%;
  transform: translate(-50%, -50%);
  @media screen and (min-width: 768px) {
    display: block;
  }
`

const CirclesContainerRight = styled.div`
  position: absolute;
  width: 20%;
  height: 20%;
  top: 30%;
  left: 43%;
  transform-style: preserve-3d;
  animation: floatF 8s ease infinite 3s;
  @media (min-width: 768px) {
    top: 50%;
  }
  @media (min-width: 1024px) {
    width: 20%;
    height: 14%;
    top: auto;
    bottom: 37%;
    left: 40%;
  }
  @media (min-width: 1200px) {
    bottom: 45%;
    left: 40%;
  }
`

interface CircleSmallInterface {
  width: number
  border: number
  top: number
  left: number
  right: number
  isVisible: boolean
  delay: number
}

const CircleSmall = styled.div<CircleSmallInterface>`
  width: ${(props) => (props.width ? props.width : 30)}px;
  height: ${(props) => (props.width ? props.width : 30)}px;
  border: ${(props) => (props.border ? props.border : 8)}px solid
    ${theme.brand.blue};
  border-radius: 50%;
  position: absolute;
  transform: scale(0) perspective(200px) translateZ(50px);
  transition: all ease;
  animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  top: ${(props) => (props.top ? props.top : 30)}%;
  left: ${(props) => (props.left ? props.left : 30)}%;
  animation: ${(props) =>
    props.isVisible
      ? ` floatCirclesRightMob ${
          props.delay + 2
        }s cubic-bezier(0, 0.55, 0.45, 1) infinite ${props.delay + 3}s`
      : 'none'};
  z-index: 3;
  @media (min-width: 768px) {
    top: 40%;
  }
  @media (min-width: 1024px) {
    animation: ${(props) =>
      props.isVisible
        ? ` floatCirclesRight ${
            props.delay + 4
          }s cubic-bezier(0, 0.55, 0.45, 1) infinite ${props.delay + 3}s`
        : 'none'};
    transition: all cubic-bezier(0, 0.55, 0.45, 1);
  }
`
