import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import Button from '../shared/Button/Button'
import theme from '../styling/theme'
// import mobImg from '../../images/hp-header/team.png'
// import boy from '../../images/hp-header/man.png'
// import girl from '../../images/hp-header/woman.png'
// import linesLeft from '../../images/hp-header/red-lines-left.png'
// import linesRight from '../../images/hp-header/red-lines-right.png'
// import img from '../../images/logo-banner/crown-commercial-supplier.png'
// import img2 from '../../images/logo-banner/hm-treasury.png'

import MainGrid from '../styling/MainGrid'
import { appendUrlPartnerTracking } from '../../util/url'
import { graphql,useStaticQuery } from 'gatsby'
import TrustBox from './Trustpilot'


interface HomepageHeaderProps {
  title1: string
  title2: string
  subtitle: string
  isVisible: boolean
  btnUrl1: string
  btnTitle1: string
  btnUrl2: string
  btnTitle2: string
}
const HomepageHeader: FunctionComponent<HomepageHeaderProps> = ({
  isVisible,
  title1,
  title2,
  subtitle,
  btnUrl1,
  btnTitle1,
  btnUrl2,
  btnTitle2,
}) => {


const imagesCollection = useStaticQuery(graphql`
  query img{
    team: allFile(filter: {childrenImageSharp: {elemMatch: {fluid: {originalName: {eq: "team.png"}}}}}) {
      edges {
        node {
          id
          childImageSharp {
            fluid(maxWidth: 660,toFormat: WEBP) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    },
    man: allFile(filter: {childrenImageSharp: {elemMatch: {fluid: {originalName: {eq: "man.png"}}}}}) {
      edges {
        node {
          id
          childImageSharp {
            fluid(maxWidth: 1080,toFormat: WEBP) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }, 
    woman: allFile(filter: {childrenImageSharp: {elemMatch: {fluid: {originalName: {eq: "woman.png"}}}}}) {
      edges {
        node {
          id
          childImageSharp {
            fluid(maxWidth: 1080,toFormat: WEBP) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    },   
    crownCommercialSupplier: allFile(filter: {childrenImageSharp: {elemMatch: {fluid: {originalName: {eq: "crown-commercial-supplier.png"}}}}}) {
      edges {
        node {
          id
          childImageSharp {
            fluid(maxWidth: 1080,toFormat: WEBP) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    },   
    hmTreasury: allFile(filter: {childrenImageSharp: {elemMatch: {fluid: {originalName: {eq: "hm-treasury.png"}}}}}) {
      edges {
        node {
          id
          childImageSharp {
            fluid(maxWidth: 1080,toFormat: WEBP) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    },
    linesLeft: allFile(filter: {childrenImageSharp: {elemMatch: {fluid: {originalName: {eq: "red-lines-left.png"}}}}}) {
      edges {
        node {
          id
          childImageSharp {
            fluid(maxWidth: 1080,toFormat: WEBP) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    },
    linesRight: allFile(filter: {childrenImageSharp: {elemMatch: {fluid: {originalName: {eq: "red-lines-right.png"}}}}}) {
      edges {
        node {
          id
          childImageSharp {
            fluid(maxWidth: 1080,toFormat: WEBP) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }   
  }
`)

const mobImg = imagesCollection.team.edges[0].node.childImageSharp.fluid.src
const boy = imagesCollection.man.edges[0].node.childImageSharp.fluid.src
const girl = imagesCollection.woman.edges[0].node.childImageSharp.fluid.src
const img = imagesCollection.crownCommercialSupplier.edges[0].node.childImageSharp.fluid.src
const img2 = imagesCollection.hmTreasury.edges[0].node.childImageSharp.fluid.src
const linesRight = imagesCollection.linesRight.edges[0].node.childImageSharp.fluid.src
const linesLeft = imagesCollection.linesLeft.edges[0].node.childImageSharp.fluid.src


  const circlesLeftNumber = [
    {
      width: 30,
      border: 5,
      top: 110,
      left: -10,
    },
    {
      width: 20,
      border: 3,
      top: 90,
      left: 6,
    },
    {
      width: 10,
      border: 2,
      top: 105,
      left: 0,
    },
    {
      width: 12,
      border: 3,
      top: 80,
      left: 22,
    },
    {
      width: 22,
      border: 4,
      top: 105,
      left: -10,
    },
    {
      width: 18,
      border: 4,
      top: 70,
      left: 5,
    },
  ]

  const circlesRightNumber = [
    {
      width: 28,
      border: 5,
      top: 100,
      right: 0,
    },
    {
      width: 14,
      border: 3,
      top: 90,
      right: 5,
    },
    {
      width: 10,
      border: 2,
      top: 80,
      right: 8,
    },
    {
      width: 12,
      border: 3,
      top: 70,
      right: 12,
    },
    {
      width: 22,
      border: 4,
      top: 90,
      right: 6,
    },
    {
      width: 18,
      border: 4,
      top: 92,
      right: 0,
    },
  ]
  const logos = [
    {
      imgSrc: img,
      alt: 'Crown Commercial Service Supplier logo',
    },
    {
      imgSrc: img2,
      alt: 'HM Treasury logo',
    },
  ]

  return (
    <Container>
      <MobileImageContainer>
        <MobileImage src={mobImg} alt="man and woman with laptops"/>
      </MobileImageContainer>
      <CirclesContainerRightMob>
        {circlesRightNumber &&
          circlesRightNumber.map((circle, key) => {
            return (
              <CircleSmall2
                key={key}
                isVisible={isVisible}
                width={circle.width}
                border={circle.border}
                top={circle.top}
                right={circle.right}
                delay={key + 1}
                left={0}
              />
            )
          })}
      </CirclesContainerRightMob>
      <CirclesContainerLeftMob>
        {circlesLeftNumber &&
          circlesLeftNumber.map((circle, key) => {
            return (
              <CircleSmall1
                key={key}
                isVisible={isVisible}
                width={circle.width}
                border={circle.border}
                top={circle.top}
                left={circle.left}
                delay={key + 1}
                right={0}
              />
            )
          })}
      </CirclesContainerLeftMob>
      {isVisible && (
        <MainGrid>
          <Inner>
            <TextWrapper>
              {title1 && (
                <MainHeading>
                  <span>{title1}</span>
                </MainHeading>
              )}
              {title2 && (
                <MainHeadingBlue>
                  <span>{title2}</span>
                </MainHeadingBlue>
              )}
              {btnUrl1 && (
                <ButtonContainer>
                
                  <Button href={appendUrlPartnerTracking(btnUrl1)}>
                    {btnTitle1}
                  </Button>
                  {btnUrl2 && (
                    <Button
                     
                      secondary
                      href={appendUrlPartnerTracking(btnUrl2)}
                    >
                      {btnTitle2}
                    </Button>
                  )}
                </ButtonContainer>
              )}
              {subtitle && <SubHeading>{subtitle}</SubHeading>}
              <Grid>
                {/*logos &&
                  logos.map((item, key) => {
                    return <Img src={item.imgSrc} alt={item.alt} key={key} />
                  })*/}
                <TrustBox />
              </Grid>
            </TextWrapper>
            <ImgWrapper>
              <BigCircle isVisible={isVisible} />

              <GirlWrapper>
                <Girl src={girl} alt="Girl with laptop" isVisible={isVisible} />
                <LineLeft4 src={linesRight} isVisible={isVisible} />
                <CirclesContainerRight>
                  {circlesRightNumber &&
                    circlesRightNumber.map((circle, key) => {
                      return (
                        <CircleSmall2
                          key={key}
                          isVisible={isVisible}
                          width={circle.width}
                          border={circle.border}
                          top={circle.top}
                          right={circle.right}
                          delay={key + 1}
                          left={0}
                        />
                      )
                    })}
                </CirclesContainerRight>
              </GirlWrapper>
              <BoyWrapper>
                <Boy src={boy} alt="Boy with laptop" isVisible={isVisible} />
                <LineLeft1 src={linesLeft} isVisible={isVisible} />
                <CirclesContainerLeft>
                  {circlesLeftNumber &&
                    circlesLeftNumber.map((circle, key) => {
                      return (
                        <CircleSmall1
                          key={key}
                          isVisible={isVisible}
                          width={circle.width}
                          border={circle.border}
                          top={circle.top}
                          left={circle.left}
                          delay={key + 1}
                          right={0}
                        />
                      )
                    })}
                </CirclesContainerLeft>
              </BoyWrapper>
            </ImgWrapper>
          </Inner>
        </MainGrid>
      )}
    </Container>
  )
}

export default HomepageHeader

const Container = styled.section`
  background-color: ${theme.background.hpHeader};
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 10px 0 50px;
  min-height: 260px;
  @media screen and (min-width: 1024px) {
    padding: 10rem 0 6.4rem;
    flex-direction: row;
  }
`

const Inner = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column-reverse;
  position: relative;
  & h3,
  h6 {
  }

  & h3 {
    font-weight: bold;
    margin-bottom: 14px;

    @media screen and (min-width: 1500px) and (max-width: 3000px) {
      font-size: 2.8vw;
      line-height: 3.2vw;
    }
    @media screen and (min-width: 3000px) {
      font-size: 84px;
      line-height: 94px;
    }
  }

  & h6 {
    @media screen and (min-width: 1500px) and (max-width: 3000px) {
      font-size: 0.9vw;
      line-height: 1.4vw;
    }
    @media screen and (min-width: 3000px) {
      font-size: 30px;
      line-height: 40px;
    }
  }

  @media (min-width: 1024px) {
    flex-direction: row;
  }
`
const TextWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  max-width: 465px;
  position: relative;
  margin-top: -35vw;
  z-index: 8;
  @media (min-width: 1024px) {
    position: relative;
    max-width: 35%;
    min-height: 32vw;
    margin-top: 0;
    // padding-left: 4vw;
  }
`

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 420px;
  margin-top: 15px;
  & a:first-of-type {
    margin-bottom: 10px;
  }

  @media (min-width: 768px) {
    flex-direction: row;
    margin-top: 45px;
    & a:first-of-type {
      margin-bottom: 0px;
      margin-right: 20px;
    }
  }
  @media (min-width: 1024px) {
    max-width: 420px;
    margin-top: 45px;
  }

  @media screen and (min-width: 1500px) {
    max-width: 20vw;
  }
`
const SubHeading = styled.h6`
  margin-top: 15px;

  @media (min-width: 768px) {
    margin-top: 30px;
  }
`

const ImgWrapper = styled.div`
  width: 100%;
  position: relative;
  perspective: 1000px;
  display: none;
  @media (min-width: 1024px) {
    display: flex;
    justify-content: center;
    max-width: 60%;
    margin-bottom: 0;
  }
`
const BoyWrapper = styled.div`
  width: 100%;
  min-width: 250px;
  height: 100%;
  position: relative;
  left: 20vw;
  top: 40px;
  max-width: 900px;
  z-index: 8;
  @media (min-width: 1024px) {
    top: -60px;
    position: absolute;
    left: 12%;
    // left: 0%;
    width: 40vw;
    animation: float 6s ease-in-out infinite 6s;
  }
  @media screen and (min-width: 1500px) {
    left: 7%;
  }
`
const GirlWrapper = styled(BoyWrapper)`
  margin-top: 20px;

  left: -14vw;
  @media screen and (min-width: 1024px) {
    margin-top: 30px;
    animation: floatF 8s ease infinite 3s;
  }
  @media screen and (min-width: 1024px) {
    width: 38vw;
    left: 33%;
  }
`
interface BigCircleProps {
  isVisible: boolean
}
const BigCircle = styled.div<BigCircleProps>`
  width: 50vw;
  height: 50vw;
  border: 6vw solid ${theme.brand.blue};
  opacity: 0.4;
  border-radius: 50%;
  transform: scale(0);
  position: absolute;
  top: 0;
  transform-style: preserve-3d;
  animation: ${(props) => (props.isVisible ? 'scaleInCircle' : 'none')} 1.8s
    cubic-bezier(0.33, 1, 0.68, 1) forwards 0.2s;
  @media screen and (min-width: 1024px) {
    top: -90px;
    max-height: 850px;
    max-width: 850px;
  }

  @media screen and (min-width: 1024px) and (max-width: 3000px) {
    width: 37vw;
    height: 37vw;
    border: 5vw solid ${theme.brand.blue};
  }

  @media (min-width: 3000px) {
    width: 1200px;
    height: 1200px;
    border: 120px solid ${theme.brand.blue};
  }
`

interface GirlProps {
  isVisible: boolean
}
const Girl = styled.img<GirlProps>`
  margin: 0 auto;
  position: relative;

  @media screen and (min-width: 1024px) {
    // width: 100%;
    width: auto;
    max-height: 1000px;
    margin-top: 18px;
    transform: scale(0);
    transform-style: preserve-3d;
    transform-origin: center 40%;
    animation: ${(props) =>
      props.isVisible ? 'headerPopInF 1.8s forwards 1s' : 'none'};
  }
`
interface BoyProps {
  isVisible: boolean
}
const Boy = styled.img<BoyProps>`
  position: relative;
  z-index: 5;
  transform-style: preserve-3d;
  @media screen and (min-width: 1024px) {
    width: auto;
    max-height: 1000px;
    transform: scale(0);
    transform-origin: 20% 40%;
    animation: ${(props) =>
      props.isVisible ? 'headerPopInF 1.8s forwards 1.8s' : 'none'};
  }
`
const CirclesContainerRight = styled.div`
  position: absolute;
  width: 20%;
  height: 20%;
  top: 16%;
  left: 56%;
  transform-style: preserve-3d;
  animation: floatF 8s ease infinite 3s;
  @media (min-width: 1024px) and (max-width: 1300px) {
    width: 20%;
    height: 14%;
    top: 14%;
    left: 58%;
  }
  @media (min-width: 2000px) {
    left: 40%;
  }
  @media (min-width: 3000px) {
    height: 18%;
    width: 24%;
    left: 35%;
  }
`
const CirclesContainerLeft = styled(CirclesContainerRight)`
  top: 20%;
  left: 15%;
  z-index: 5;
  @media (min-width: 1024px) and (max-width: 1300px) {
    top: 15%;
    left: 12%;
  }
  @media (min-width: 3000px) {
    left: 15%;
  }
`

interface CircleSmallProps {
  top: number
  left: number
  right: number
  isVisible: boolean
  delay: number
  width: number
  border: number
}
const CircleSmall1 = styled.div<CircleSmallProps>`
  width: ${(props) => (props.width ? props.width : 30)}px;
  height: ${(props) => (props.width ? props.width : 30)}px;
  border: ${(props) => (props.border ? props.border : 8)}px solid
    ${theme.brand.blue};
  border-radius: 50%;
  position: absolute;
  transform: scale(0) perspective(200px) translateZ(50px);
  transition: all ease;
  animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  top: ${(props) => (props.top ? props.top : 30)}%;
  left: ${(props) => (props.left ? props.left : 30)}%;
  animation: ${(props) =>
    props.isVisible
      ? ` floatCirclesLeftMob ${
          props.delay + 2
        }s cubic-bezier(0, 0.55, 0.45, 1) infinite ${props.delay + 3}s`
      : 'none'};
  z-index: 3;
  @media (min-width: 1024px) {
    animation: ${(props) =>
      props.isVisible
        ? ` floatCirclesLeft ${
            props.delay + 4
          }s cubic-bezier(0, 0.55, 0.45, 1) infinite ${props.delay + 3}s`
        : 'none'};
    transition: all cubic-bezier(0, 0.55, 0.45, 1);
  }
`
interface CircleSmall2Props {
  right: number
  isVisible: boolean
  delay: number
}
const CircleSmall2 = styled(CircleSmall1)<CircleSmall2Props>`
  right: ${(props) => (props.right ? props.right : 30)}%;
  animation: ${(props) =>
    props.isVisible
      ? ` floatCirclesRightMob ${props.delay + 2}s ease infinite ${
          props.delay + 1.5
        }s`
      : 'none'};
  left: auto;
  @media (min-width: 1024px) {
    animation: ${(props) =>
      props.isVisible
        ? ` floatCirclesRight ${
            props.delay + 4
          }s cubic-bezier(0, 0.55, 0.45, 1) infinite ${props.delay + 1.5}s`
        : 'none'};
  }
`
interface LineLeft4Interface {
  isVisible: boolean
}

const LineLeft1 = styled.img<LineLeft4Interface>`
  animation: ${(props) =>
    props.isVisible ? 'linesSound1 2.5s ease infinite 3.5s' : 'none'};
  transform-origin: bottom left;
  transition: transform ease 0.2s;
  width: 100%;
  z-index: 5;
  position: absolute;
  left: 0;
  transform: scale(0);
  width: auto;
  max-height: 1000px;
`
const LineLeft2 = styled(LineLeft1)``
const LineLeft3 = styled(LineLeft1)``

const LineLeft4 = styled(LineLeft1)`
  animation: ${(props) =>
    props.isVisible ? 'linesSound1 2s ease infinite 3s' : 'none'};
  transform-origin: bottom right;
`
const LineLeft5 = styled(LineLeft4)``
const LineLeft6 = styled(LineLeft4)``
const MainHeading = styled.h1`
  font-weight: bold;
  letter-spacing: -1px;
  text-transform: none;
  font-size: 30px;
  line-height: 40px;
  @media screen and (min-width: 768px) {
    font-size: 70px;
    line-height: 93px;
    letter-spacing: -2.7px;
  }
  @media screen and (min-width: 1024px) {
    padding: 0;
    background: none;
    width: auto;
    font-size: 70px;
    line-height: 70px;
  }

  & span {
    background: white;
    box-decoration-break: clone;
    display: inline;
    padding: 0px 6px 0px 0;
    font-family: 'Titillium Web';
    @media screen and (min-width: 1024px) {
      background: none;
      padding: 0px;
    }
  }
`

const MainHeadingBlue = styled(MainHeading)`
  color: ${theme.brand.blue};
  // margin-top: -3px;
  @media screen and (min-width: 1024px) {
    padding: 0;
    margin-top: 0;
  }
  & span {
    color: ${theme.brand.blue};
  }
`
const MobileImageContainer = styled.div`
  height: auto;
  min-height: 300px;
  @media screen and (min-width: 1024px) {
    display: none;
  }
`

const MobileImage = styled.img`
  display: block;
  margin: 0 auto;
  @media screen and (min-width: 1024px) {
    display: none;
  }
`
const CirclesContainerRightMob = styled(CirclesContainerRight)`
  height: 12%;
  width: 12%;
  top: 40vw;
  left: 67%;
  @media (min-width: 768px) {
    top: 32vw;
  }
  @media (min-width: 1024px) {
    display: none;
  }
`
const CirclesContainerLeftMob = styled(CirclesContainerLeft)`
  top: 40vw;
  left: 15%;
  height: 12%;
  width: 12%;
  @media (min-width: 768px) {
    top: 32vw;
  }
  @media (min-width: 1024px) {
    display: none;
  }
`

const Grid = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 38px;
  @media (min-width: 1024px) {
    margin-top: 50px;
  }
`
const Img = styled.img`
  margin-right: 18px;
  height: 36px;
  @media (min-width: 767px) {
    margin-right: 23px;
    height: 40px;
  }
`
