import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import theme from '../styling/theme'
import '../shared/Animations/Animations.css'
import LargeGrid from '../styling/LargeGrid'

interface DotsAnimationProps {
  isVisible: boolean
  withBobHeading: string
  withBobText: string
  withBobBody: string
  withoutBobHeading: string
  withoutBobText: string
  withoutBobBody: string
}

const DotsAnimation: FunctionComponent<DotsAnimationProps> = ({
  isVisible,
  withBobHeading,
  withBobText,
  withBobBody,
  withoutBobHeading,
  withoutBobText,
  withoutBobBody,
}) => {
  const removeCirclesWithBob = (id: string) => {
    if (typeof document !== 'undefined') {
      const node = document.getElementById(id)
      if (node !== null) {
        node.querySelectorAll('*').forEach((n) => n.remove())
      }
    }
  }

  const drawCirclesWithBob = (
    id: string,
    circlesNumber: number,
    radius: number,
    animateDot1: number,
    animateDot2: number,
    animateDot3: number
  ) => {
    const div = 360 / circlesNumber
    const parentdiv = document.getElementById(id)
    const offsetToParentCenter = parseInt(parentdiv.offsetWidth / 2)
    const offsetToChildCenter = 20
    const totalOffset = offsetToParentCenter - offsetToChildCenter

    for (let i = 1; i <= circlesNumber; i++) {
      const childdiv = document.createElement('div')
      childdiv.className = 'div2'
      childdiv.id = i + 'div'

      // calcs dot's position
      const y = Math.sin(div * i * (Math.PI / 180)) * radius
      const x = Math.cos(div * i * (Math.PI / 180)) * radius

      childdiv.style.top = `${(y + totalOffset).toString()}px`
      childdiv.style.left = `${(x + totalOffset).toString()}px`

      if (id.includes('out')) {
        if (i === animateDot1 || i === animateDot2 || i === animateDot3) {
          childdiv.style.animation = `dotBackground 0.4s ease forwards  ${
            i * 0.01 + Math.random()
          }s`
          childdiv.style.animationIterationCount = 1
        }
      } else if (i === animateDot1 || i === animateDot2 || i === animateDot3) {
        childdiv.style.backgroundColor = '#e2e2e2'
      } else {
        childdiv.style.animation = `dotBackground 0.3s ease forwards ${(
          i * 0.04 +
          Math.random()
        ).toFixed(2)}s`
        childdiv.style.animationIterationCount = 1
      }
      parentdiv.appendChild(childdiv)
    }
  }

  if (isVisible) {
    drawCirclesWithBob('withBob1', 56, 153, 57, 57, 57)
    drawCirclesWithBob('withBob2', 49, 134, 40, 30, 12)
    drawCirclesWithBob('withBob3', 42, 115, 40, 30, 12)
    drawCirclesWithBob('withBob4', 35, 96, 40, 30, 12)
    drawCirclesWithBob('withBob5', 28, 77, 40, 30, 12)
    drawCirclesWithBob('withBob6', 21, 58, 40, 30, 12)
    drawCirclesWithBob('withBob7', 14, 39, 5, 6, 16)
    drawCirclesWithBob('withBob8', 7, 20, 2, 5, 12)
    drawCirclesWithBob('withBob9', 1, 1, 40, 30, 12)

    // without Bob
    drawCirclesWithBob('withoutBob1', 56, 153, 45, 8, 52)
    drawCirclesWithBob('withoutBob2', 49, 134, 49, 26, 20)
    drawCirclesWithBob('withoutBob3', 42, 115, 36, 9, 25)
    drawCirclesWithBob('withoutBob4', 35, 96, 33, 4, 15)
    drawCirclesWithBob('withoutBob5', 28, 77, 10, 20, 5)
    drawCirclesWithBob('withoutBob6', 21, 58, 2, 14, 21)
    drawCirclesWithBob('withoutBob7', 14, 39, 16, 15, 20)
    drawCirclesWithBob('withoutBob8', 7, 20, 5, -15, -12)
    drawCirclesWithBob('withoutBob9', 1, 1, 15, 10, 20)
  } else {
    for (let i = 0; i < 9; i++) {
      removeCirclesWithBob(`withBob${i + 1}`)
      removeCirclesWithBob(`withoutBob${i + 1}`)
    }
  }

  return (
    <OuterContainer>
      <LargeGrid noPaddingMobile noPaddingTablet>
        <Wrapper>
          <Card background={theme.background.grey}>
            <InnerWrapper>
              <PTop>
                <b>With</b> Bob’s Business
              </PTop>
              <Container>
                <CircleBorder id="withBob1" />
                <CircleBorder2 id="withBob2" />
                <CircleBorder3 id="withBob3" />
                <CircleBorder4 id="withBob4" />
                <CircleBorder5 id="withBob5" />
                <CircleBorder6 id="withBob6" />
                <CircleBorder7 id="withBob7" />
                <CircleBorder8 id="withBob8" />
                <CircleBorder9 id="withBob9" />
              </Container>
              {withBobHeading && <h4>{withBobHeading}</h4>}
              {withBobText && <PMain>{withBobText}</PMain>}
              <Line />

              {withBobBody && <PSecondary>{withBobBody}</PSecondary>}
            </InnerWrapper>
            <Vs>VS</Vs>
          </Card>
          <Card background={theme.background.hpHeader}>
            <InnerWrapper>
              <PTop>
                <b>Without</b> Bob’s Business
              </PTop>

              <Container>
                <CircleBorder id="withoutBob1" />
                <CircleBorder2 id="withoutBob2" />
                <CircleBorder3 id="withoutBob3" />
                <CircleBorder4 id="withoutBob4" />
                <CircleBorder5 id="withoutBob5" />
                <CircleBorder6 id="withoutBob6" />
                <CircleBorder7 id="withoutBob7" />
                <CircleBorder8 id="withoutBob8" />
                <CircleBorder9 id="withoutBob9" />
              </Container>
              {withoutBobHeading && <h4>{withoutBobHeading}</h4>}
              {withoutBobText && <PMain>{withoutBobText}</PMain>}
              <Line />

              {withoutBobBody && <PSecondary>{withoutBobBody}</PSecondary>}
            </InnerWrapper>
          </Card>
        </Wrapper>
      </LargeGrid>
    </OuterContainer>
  )
}

export default DotsAnimation

const OuterContainer = styled.section`
  @media (min-width: 1024px) {
    background: linear-gradient(
      90deg,
      ${theme.background.grey} 0%,
      ${theme.background.grey} 50%,
      ${theme.background.hpHeader} 51%,
      ${theme.background.hpHeader} 100%
    );
  }
`

const Wrapper = styled.div`
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  @media screen and (min-width: 1024px) {
    flex-direction: row;
  }
`
interface CardProps {
  background: string
}
const Card = styled.div<CardProps>`
  width: 100%;
  padding: 50px 0;
  background: ${(props) => props.background && props.background};
  position: relative;
  &:first-of-type {
    border-bottom: 1px solid ${theme.grey.heylineDots};
  }
  @media screen and (min-width: 1024px) {
    width: 50%;
    padding: 12rem 0 15rem;
    &:first-of-type {
      border-right: 1px solid ${theme.grey.heylineDots};
      border-bottom: none;
    }
  }

  & h4 {
    font-weight: bold;
    margin-bottom: 2rem;
  }
`
const InnerWrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: ${theme.text.main};
  font-family: Montserrat;
  @media screen and (min-width: 1024px) {
    max-width: 46rem;
    padding: 0;
  }
`

const PTop = styled.p`
  margin-bottom: 3.6rem;
`

const Container = styled.div`
  width: 100%;
  max-width: 280px;
  height: 100%;
  min-height: 350px;
  position: relative;

  @media screen and (max-width: 450px) {
    transform: scale(0.8);
    min-height: 300px;
  }
  @media screen and (min-width: 1024px) {
    max-width: 350px;
    margin-bottom: 14px;
    min-height: 350px;
  }
`
const PMain = styled.p`
  font-weight: 300;
  margin-bottom: 4rem;
`
const Line = styled.div`
  width: 100%;
  max-width: 208px;
  height: 1px;
  border-top: 1px solid #1d1e20;
  margin-bottom: 27px;
`
const PSecondary = styled(PMain)`
  margin-bottom: 0;
`

const CircleBorder = styled.div`
  border: none;
  position: absolute;
  top: 50%;
  left: 54%;
  transform: translate(-50%, -50%);
  width: 300px;
  height: 300px;
  border-radius: 100%;
  margin: 0 auto;
  & .div2 {
    position: absolute;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: #e2e2e2;
  }
`
const CircleBorder2 = styled(CircleBorder)`
  width: 270px;
  height: 270px;
`
const CircleBorder3 = styled(CircleBorder)`
  width: 250px;
  height: 250px;
`
const CircleBorder4 = styled(CircleBorder)`
  width: 230px;
  height: 230px;
`
const CircleBorder5 = styled(CircleBorder)`
  width: 210px;
  height: 210px;
`
const CircleBorder6 = styled(CircleBorder)`
  width: 190px;
  height: 190px;
`
const CircleBorder7 = styled(CircleBorder)`
  width: 170px;
  height: 170px;
`
const CircleBorder8 = styled(CircleBorder)`
  width: 150px;
  height: 150px;
`
const CircleBorder9 = styled(CircleBorder)`
  width: 130px;
  height: 130px;
`
const Vs = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid ${theme.brand.blue};
  position: absolute;
  bottom: 0%;
  left: 50%;
  transform: translate(-50%, 50%);
  background: white;
  font-size: 14px;
  line-height: 22px;
  color: ${theme.brand.blue};
  font-weight: bold;
  z-index: 2;
  font-family: Montserrat;
  @media (min-width: 1024px) {
    width: 63px;
    height: 63px;
    top: 60%;
    left: 100%;
    font-size: 24px;
    line-height: 32px;
  }
`
